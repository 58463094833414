<template>
  <div>
    <div class="model_wrap" v-if="closeVideo">
      <video :src="videoSrc" controls></video>
    </div>
    <div class="m_UCL360_page" v-if="isMobile" @click.stop="closeVideo = false">
      <div class="banner_wrap">
        <img class="banner" src="@/assets/UCL360_banner_m.png" />
        <div class="banner_content">
          <img class="logo" src="@/assets/UCL360_text_m.png" alt="" />
          <p class="des">
            全空间智能测量，让空间数据可感知，真实空间数据自动采集和上传。从物理空间到数字空间全面提高各阶段运行效率和工程质量可控度。
          </p>
          <p class="btn_buy" @click="showMore('/Contact')">购买咨询</p>
        </div>
      </div>

      <div class="video_wrap">
        <img class="banner" src="@/assets/UCL360_banner2_m.png" />
        <div class="banner_content">
          <p class="title">一键操作 自动智能</p>
          <p class="des">
            光机电算测一体化，一键式操作，完成自动找平、自动建模、自动输出每一面墙的垂直度、平整度、面积、天花板地板的平行度极差、阴阳角、开间进深净高等实测实量数据，自动形成3D数据上墙、输出数据报表
          </p>
          <div
            class="btn_video"
            @click.stop="openVideo('https://unre-ucl360-services.oss-cn-hangzhou.aliyuncs.com/www-video/ai.c4d430f6.mp4')"
          >
            <p>观看完整视频</p>
            <img src="@/assets/play_video_icon.png" />
          </div>
        </div>
      </div>

      <div class="scan_wrap">
        <img class="banner" src="@/assets/UCL360_banner3_m.png" />
        <div class="scan_content">
          <p class="title">精准高效 多端协同</p>
          <p class="des">
            测量精度达到1.5mm，3.5分钟完成整个房间的扫描建模，可快速完成数据分析输出打通手机、PAD、PC、云端多端协同共享数据，实时分享一线真实数据
          </p>
        </div>
      </div>

      <div class="d3_wrap">
        <img class="banner" src="@/assets/UCL360_banner4_m.png" />
        <div class="d3_content">
          <p class="title">3D建模 BIM比对</p>
          <p class="des">
            通过智能化3D逆向建模、语义识别，可以和CAD、BIM进行拟合比对，为BIM
            实施运维、装修等场景提供更为精准高效的决策支持数据
          </p>
          <div class="line">
            <img src="@/assets/UCL360_icon1.png" />
            <img src="@/assets/UCL360_icon2.png" />
            <img src="@/assets/UCL360_icon3.png" />
          </div>
        </div>
      </div>

      <div class="fun_wrap">
        <p class="title">主要功能</p>
        <p class="des">
          内置嵌入式边缘加速算法（CV/CG＆AI）以及深度优化并行异构计算能力，可迅速分析3D点云并且输出墙壁、天花、地面、窗口和门洞口位置实时精确测算出实测实量所需各种指标的数据
        </p>
        <div class="fun_content">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in funList"
                :key="index"
              >
                <img :src="item.img_m"  @click.stop="openVideo(item.video)"/>
                <p>{{ item.des }}</p>
              </div>
            </div>
            
          </div>
        </div>
        <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
      </div>

      <div class="auto_wrap">
        <p class="title">全自动工程质量管理解决方案</p>
        <p class="des">
          与工程管理平台打通，实现自动化测量和智能化分析，测量数据随时查看，所见即所得
        </p>
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="@/assets/auto_1.png" />
              <p>图纸处理</p>
            </div>
            <div class="swiper-slide">
              <img src="@/assets/auto_2.png" />
              <p>一键测量</p>
            </div>
            <div class="swiper-slide">
              <img src="@/assets/auto_3.png" />
              <p>爆点位置查看</p>
            </div>
            <div class="swiper-slide">
              <img src="@/assets/auto_4.png" />
              <p>发送整改通知单</p>
            </div>
            <div class="swiper-slide">
              <img src="@/assets/auto_5.png" />
              <p>整改复测消项</p>
            </div>
          </div>
        </div>
      </div>

      <div class="num_wrap">
        <p class="title">数字化装修</p>
        <p class="des">
          基于人机云协同、构件识别、精准拼接，逆向建模自动化，将深化辅助设计与施工放线坐标统一保障装修顺利交付验收，赋能装修企业数字化转型
        </p>
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="@/assets/360_num_2.png" />
              <p>逆向建模单房间拼接</p>
              <p>复杂房间内自动拼接，无盲区</p>
            </div>
            <div class="swiper-slide">
              <img src="@/assets/360_num_1.png" />
              <p>逆向建模户型自动拼接</p>
              <p>现场多房间自动拼接，组成户型模型</p>
            </div>
            <div class="swiper-slide">
              <img src="@/assets/360_num_3.png" />
              <p>房间找方</p>
              <p>根据房间问题特征，生成最大化找方盒子</p>
            </div>
          </div>
        </div>
      </div>

      <div class="outside_wrap">
        <p class="title">外立面测量</p>
        <p class="des">
          数据与图纸结合生成结合图纸的外墙爆点位置图、分楼层爆点位置图便于整改提供可融合室内外全貌的结构化数据
        </p>
        <div class="line">
          <div class="item">
            <img src="@/assets/outside_m_1.png" />
            <p>分楼层垂平数据</p>
          </div>
          <div class="item">
            <img src="@/assets/outside_m_2.png" />
            <p>阳台面垂平数据</p>
          </div>
        </div>
        <div class="item_one">
          <img src="@/assets/outside_m_3.png" />
          <p>楼栋侧面连续垂平数据</p>
        </div>
      </div>
    </div>

    <div class="UCL360_page" v-else @click.stop="closeVideo = false">
      <div class="banner_wrap">
        <img class="banner" src="@/assets/UCL360_banner.png" />
        <div class="banner_content">
          <img class="logo" src="@/assets/UCL360_text.png" alt="" />
          <p class="des  slow animated fadeInLeft" style="margin: 0.12rem 0 0.025rem">
            全空间智能测量，让空间数据可感知，真实空间数据自动采集和上传。
          </p>
          <p class="des  slow animated fadeInLeft" style="margin: 0px 0 0.25rem">
            从物理空间到数字空间全面提高各阶段运行效率和工程质量可控度。
          </p>
          <p class="btn_buy" @click="showMore('/Contact')">购买咨询</p>
        </div>
      </div>


      <div class="video_wrap">
        <img class="banner" src="@/assets/UCL360_banner2.png" />
        <div class="banner_content">
          <p class="title">一键操作 自动智能</p>
          <p class="des wow fadeInUp slow animated" style="margin: 0.12rem 0 0.025rem">
            光机电算测一体化，一键式操作，完成自动找平、自动建模、自动输出每一面墙的垂直度、平整度、面积、天花板
          </p>
          <p class="des wow fadeInUp slow animated" style="margin: 0px 0 0.25rem">
            地板的平行度极差、阴阳角、开间进深净高等实测实量数据，自动形成3D数据上墙、输出数据报表
          </p>
          <div
            class="btn_video"
            @click.stop="openVideo('https://unre-ucl360-services.oss-cn-hangzhou.aliyuncs.com/www-video/ai.c4d430f6.mp4')"
          >
            <p>观看完整视频</p>
            <img src="@/assets/play_video_icon.png" />
          </div>
        </div>
      </div>

      <div class="scan_wrap">
        <img class="banner" src="@/assets/UCL360_banner3.png" />
        <div class="scan_content">
          <p class="title">精准高效 多端协同</p>
          <p class="des  wow fadeInUp slow animated" style="margin: 0.12rem 0 0.025rem">
            测量精度达到1.5mm，3.5分钟完成整个房间的扫描建模，可快速完成数据分析输出打通手机、PAD、PC、云端
          </p>
          <p class="des wow fadeInUp slow animated" style="margin: 0px 0 0.25rem">
            多端协同共享数据，实时分享一线真实数据
          </p>
        </div>
      </div>

      <div class="d3_wrap">
        <img class="banner" src="@/assets/UCL360_banner4.png" />
        <div class="d3_content">
          <p class="title">3D建模 BIM比对</p>
          <p class="des  wow fadeInUp slow animated" style="margin: 0.12rem 0 0.025rem">
            通过智能化3D逆向建模、语义识别，可以和CAD、BIM进行拟合比对，为BIM
            实施运维、装修等场景
          </p>
          <p class="des wow fadeInUp slow animated" style="margin: 0px 0 0.25rem">
            提供更为精准高效的决策支持数据
          </p>
          <div class="line">
            <img src="@/assets/UCL360_icon1.png" />
            <img src="@/assets/UCL360_icon2.png" />
            <img src="@/assets/UCL360_icon3.png" />
          </div>
        </div>
      </div>

      <div class="fun_wrap">
        <p class="title">主要功能</p>
        <p class="des" style="margin: 0.12rem 0 0.025rem">
          内置嵌入式边缘加速算法（CV/CG＆AI）以及深度优化并行异构计算能力，可迅速分析3D点云并且输出墙壁、天花、地面、窗口和门洞口位置
        </p>
        <p class="des" style="margin: 0px 0 0.25rem">
          实时精确测算出实测实量所需各种指标的数据
        </p>
        <div class="fun_content">
          <div class="left_menu">
            <div
              class="item"
              :class="{ active: ActiveFunIndex === index }"
              v-for="(item, index) in funList"
              :key="index"
              @click="swiperTo(index)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="right_content">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in funList"
                  :key="index"
                >
                  
                  <img :src="item.img" @click.stop="openVideo(item.video)" :style="{cursor:item.video?'pointer':''}"/>
                  <p>{{ item.des }}</p>
                </div>
              </div>
              <!-- Add Pagination -->
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="auto_wrap">
        <p class="title">全自动工程质量管理解决方案</p>
        <p class="des">
          与工程管理平台打通，实现自动化测量和智能化分析，测量数据随时查看，所见即所得
        </p>
        <div class="line  wow fadeInUp slow animated">
          <div class="item">
            <img src="@/assets/auto_1.png" />
            <p>图纸处理</p>
          </div>
          <div class="item">
            <img src="@/assets/auto_2.png" />
            <p>一键测量</p>
          </div>
          <div class="item">
            <img src="@/assets/auto_3.png" />
            <p>爆点位置查看</p>
          </div>
          <div class="item">
            <img src="@/assets/auto_4.png" />
            <p>发送整改通知单</p>
          </div>
          <div class="item">
            <img src="@/assets/auto_5.png" />
            <p>整改复测消项</p>
          </div>
        </div>
      </div>

      <div class="num_wrap">
        <p class="title">数字化装修</p>
        <p class="des" style="margin: 0.12rem 0 0.025rem">
          基于人机云协同、构件识别、精准拼接，逆向建模自动化，将深化辅助设计与施工放线坐标统一
        </p>
        <p class="des" style="margin: 0px 0 0.25rem">
          保障装修顺利交付验收，赋能装修企业数字化转型
        </p>
        <div class="line  wow fadeInUp slow animated">
          <div class="item">
            <img src="@/assets/360_num_2.png" />
            <p>逆向建模单房间拼接</p>
            <p>复杂房间内自动拼接，无盲区</p>
          </div>
          <div class="item">
            <img src="@/assets/360_num_1.png" />
            <p>逆向建模户型自动拼接</p>
            <p>现场多房间自动拼接，组成户型模型</p>
          </div>
          <div class="item">
            <img src="@/assets/360_num_3.png" />
            <p>房间找方</p>
            <p>根据房间问题特征，生成最大化找方盒子</p>
          </div>
        </div>
      </div>

      <div class="outside_wrap">
        <p class="title">外立面测量</p>
        <p class="des" style="margin: 0.12rem 0 0.025rem">
          数据与图纸结合生成结合图纸的外墙爆点位置图、分楼层爆点位置图便于整改
        </p>
        <p class="des" style="margin: 0px 0 0.25rem">
          提供可融合室内外全貌的结构化数据
        </p>
        <div class="line  wow fadeInUp slow animated">
          <div class="item">
            <img src="@/assets/outside_1.png" />
            <p>分楼层垂平数据</p>
          </div>
          <div class="item">
            <img src="@/assets/outside_2.png" />
            <p>阳台面垂平数据</p>
          </div>
          <div class="item">
            <img src="@/assets/outside_3.png" />
            <p>楼栋侧面连续垂平数据</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import {WOW} from "wowjs"
export default {
  name: "UCL360",
  data() {
    return {
      isMobile: false,
      closeVideo: false,
      swiper: null,
      funList: [
        {
          img_m: require("@/assets/fun_m_1.png"),
          img: require("@/assets/fun_1.png"),
          title: "一键扫描",
          des: "一键测量：释放和节省了人力，一键开启扫描自动建模计算，大大提升了测量效率。",
        },
        {
          img_m: require("@/assets/fun_m_2.png"),
          img: require("@/assets/fun_2.png"),
          title: "数据上墙",
          des: "移动版“数据上墙”，随时随地查看，数据可以长时间保存云端便于追溯。",
        },
        {
          img_m: require("@/assets/fun_m_3.png"),
          img: require("@/assets/fun_3.png"),
          title: "全墙面等高线显示",
          des: "房屋整体墙面质量监控，以整体墙面数据检测来反映施工质量并以不同的颜色来显示出爆点区域从而帮助人工进行墙面整改。",
        },
        {
          img_m: require("@/assets/fun_m_4.png"),
          img: require("@/assets/fun_4.png"),
          title: "CAD助手",
          video: 'https://unre-ucl360-services.oss-cn-hangzhou.aliyuncs.com/www-video/fun_4.d27bacc0.mp4',
          des: "CAD助手",
        },
        {
          img_m: require("@/assets/fun_m_5.png"),
          img: require("@/assets/fun_5.png"),
          title: "自动生成表格",
          des: "自动生成表格",
        },
        {
          img_m: require("@/assets/fun_m_6.png"),
          img: require("@/assets/fun_6.png"),
          video: 'https://unre-ucl360-services.oss-cn-hangzhou.aliyuncs.com/www-video/fun_6.3186ab6c.mp4',
          title: "数据打印",
          des: "数据打印",
        },
        {
          img_m: require("@/assets/fun_m_7.png"),
          img: require("@/assets/fun_7.png"),
          title: "管理看板",
          des: "管理看板",
        },
      ],
      ActiveFunIndex: 0,
      videoSrc: "",
    };
  },
  components: {},
  created() {
    this.isMobile = isMobile();
    this.$nextTick(() => {
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
  },
  mounted() {
    var that = this;
    if (this.isMobile) {
      new Swiper(".fun_wrap .swiper-container", {
        pagination: {
          el: ".fun_wrap .swiper-pagination",
        },
        autoplay: true,
      });
       new Swiper(" .swiper-container", {
     
        autoplay: true,
      });
    } else {
      setTimeout(() => {
        this.swiper = new Swiper(".swiper-container", {
          autoplay: true,

          on: {
            slideChangeTransitionEnd: function () {
              that.ActiveFunIndex = this.activeIndex;
            },
          },
        });
      }, 2000);
    }
  },
  methods: {
    openVideo(src) {
      if(!src){
        return
      }
      this.videoSrc = src;
      this.closeVideo = true;
    },
    swiperTo(index) {
      this.ActiveFunIndex = index;
      this.swiper.slideTo(index);
    },
    showMore(path) {
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style lang="less">
.m_UCL360_page {
  .banner {
    display: block;
    width: 100%;
  }
  
  .banner_wrap {
    position: relative;

    .des {
      width: 9.15rem;
      text-align: left;
      margin-bottom: 0.57rem;
      line-height: 0.5rem;
      font-size: 0.32rem;
      color: #F2F2F2;
    }
    .logo {
      width: 3.85rem;
      margin-bottom: 0.57rem;
    }
    .btn_buy {
      width: 2.67rem;
      height: 0.85rem;
      background: linear-gradient(180deg, #5ed5ff 0%, #00bbff 100%);

      border-radius: 0.04rem 0.04rem 0.04rem 0.04rem;

      font-size: 0.32rem;
      color: #ffffff;
      line-height: 0.85rem;
      text-align: center;
      cursor: pointer;
      margin: 0 auto;
      &:hover {
        opacity: 0.7;
      }
    }
    .banner_content {
      position: absolute;
      top: 2.95rem;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      color: #cccccc;
    }
  }

  .video_wrap {
    position: relative;

    .title {
      font-size: 0.45rem;
      margin-bottom: 0.57rem;
      color: #fcfcfc;
      font-weight: 600;
    }
    .des {
      width: 9.15rem;
      text-align: left;
      margin-bottom: 0.57rem;
      line-height: 0.5rem;
      
color: #E6E6E6;
      font-size: 0.32rem;
    }

    .banner_content {
      position: absolute;
      top: 4.73rem;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      color: #cccccc;
    }
    .btn_video {
      width: 3.49rem;

      height: 0.81rem;
      background: rgba(162, 162, 162, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      cursor: pointer;
      color: rgba(252, 252, 252, 0.8);
      font-size: 0.27rem;
      margin: 0 auto;
      &:hover {
        opacity: 0.7;
      }
      img {
        margin-left: 0.2rem;
        width: 0.35rem;
      }
    }
  }

  .scan_wrap {
    position: relative;
    .scan_content {
      position: absolute;
      top: 1.75rem;
      left: 50%;
      transform: translateX(-50%);
      .title {
        font-size: 0.45rem;
        color: #fcfcfc;
        margin-bottom: 0.56rem;
        font-weight: 600;
      }
      .des {
        width: 9.15rem;
        text-align: left;
        font-size: 0.32rem;
        
color: #E6E6E6;
        margin-bottom: 0.63rem;
        line-height: 0.5rem;
      }
    }
  }

  .d3_wrap {
    position: relative;
    .d3_content {
      position: absolute;
      top: 5.85rem;
      left: 50%;
      transform: translateX(-50%);
      .title {
        font-size: 0.45rem;
        color: #fcfcfc;
        margin-bottom: 0.56rem;
        font-weight: 600;
      }
      .des {
        width: 9.15rem;
        text-align: left;
        font-size: 0.32rem;
        color: #FCFCFC;
        margin-bottom: 0.75rem;
        line-height: 0.5rem;
      }
      .line {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin: 0 0.19rem;
          width: 0.8rem;
        }
      }
    }
  }

  .fun_wrap {
    padding: 1.19rem 0.43rem;
    background: #FAFAFA;
    .title {
      margin-bottom: 0.56rem;
      color: #242c33;
      font-size: 0.45rem;
      font-weight: 600;
    }
    .des {
      line-height: 0.5rem;
      color: #666666;
      text-align: left;
      font-size: 0.32rem;
      margin-bottom: 0.71rem;
    }
    .swiper-pagination{
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
     .swiper-pagination-bullet {
        width: 20px;
        height: 4px;
        margin:20px 6px 0;
        background: rgba(2, 174, 236, 0.5);
        border-radius: 0;
        opacity: 1;
      }
      .swiper-pagination-bullet-active {
        width: 20px;
        height: 4px;
        background: #02aeec;
        border-radius: 0;
      }
    .swiper-container,
    .swiper_core {
      position: relative;

     
      .swiper-slide {
        position: relative;
        width: 9.15rem;
        height: 6.68rem;
        display: flex;
        align-items: center;

        background: linear-gradient(
          180deg,
          rgba(204, 204, 204, 0) 0%,
          rgba(51, 51, 51, 0.6) 100%
        );
        p {
          position: absolute;
          bottom: 0;
          left: 0;
          text-align: left;

          color: #fcfcfc;
          width: 100%;
          padding: 12px;

background: rgba(77,77,77,0.5);
        }
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }

  .auto_wrap {
    padding: 1.19rem 0.43rem;
    .title {
      margin-bottom: 0.56rem;
      color: #242c33;
      font-weight: 600;
      font-size: 0.45rem;
    }
    .des {
      line-height: 0.5rem;
      color: #666666;
      text-align: left;
      font-size: 0.32rem;
      margin-bottom: 0.71rem;
    }
    .swiper-container {
      position: relative;
    }
    .swiper-slide {
      img {
        width: 3.88rem;
        height: 8.35rem;
      }
    }
  }

  .num_wrap {
    padding: 1.19rem 0;
   background: #FAFAFA;
    .title {
      margin-bottom: 0.56rem;
      color: #242c33;
      font-size: 0.45rem;
      font-weight: 600;
    }
    .des {
      line-height: 0.5rem;
      color: #666666;
      text-align: left;
      font-size: 0.32rem;
      margin: 0 auto;
      margin-bottom: 0.71rem;
      width: 9.07rem;
    }
    .swiper-container {
      position: relative;
    }
    .swiper-slide {
      width: 8.37rem;
      position: relative;
      img {
        width: 8.21rem;
        height: 8.87rem;
      }
      p {
        position: absolute;
        bottom: 1rem;
        left: 1.2rem;
        text-align: left;

        font-size: 0.37rem;
        color: #fcfcfc;
        width: 8.51rem;
        &:last-child {
          bottom: 0.5rem;

          color: #cccccc;
          font-size: 0.29rem;
        }
      }
    }
  }

  .outside_wrap {
    padding: 1.19rem 0.43rem;
    background-image: url(../assets/outside_bg_m.png);
    background-size: cover;
    .title {
      margin-bottom: 0.56rem;
      color: #242c33;
      font-size: 0.45rem;
      font-weight: 600;
    }
    .des {
      text-align: left;
      line-height: 0.5rem;
     color: #666666;
      text-align: left;
      font-size: 0.32rem;
      margin-bottom: 0.71rem;
    }
    .line {
      display: flex;
      justify-content: space-between;
      .item {
        position: relative;
        p {
          position: absolute;
          bottom: 0.32rem;
          left: 0.32rem;
          text-align: left;

          font-size: 0.32rem;
          color: #fcfcfc;
        }
      }
      img {
        width: 4.45rem;
      }
    }
    .item_one {
      position: relative;
      img {
        width: 100%;
      }
      p {
        position: absolute;
        bottom: 0.32rem;
        left: 0.32rem;
        text-align: left;

        font-size: 0.32rem;
        color: #fcfcfc;
      }
    }
  }
}

.UCL360_page {
  .banner {
    display: block;
    width: 100%;
  }
  .banner_wrap {
    img {
      width: 100%;
    }

    .banner_content {
      position: absolute;
      top: 1.67rem;
      left: 16%;
      text-align: left;
      color: #cccccc;
    }
    .logo {
      width: 443px;
    }
    .des {
      margin: 24px 0 48px;
      // width: 656px;
      font-size: 0.095rem;

      line-height: 0.13rem;
    }
    .btn_buy {
      width: 152px;
      height: 57px;
      background: linear-gradient(180deg, #5ed5ff 0%, #00bbff 100%);
      border-radius: 3px 3px 3px 3px;
      font-size: 20px;
      color: #ffffff;
      line-height: 55px;
      text-align: center;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .video_wrap {
    position: relative;
    .banner_content {
      position: absolute;
      top: 1.54rem;
      left: 50%;
      transform: translateX(-50%);
      color: #cccccc;
      .title {
        font-size: 0.19rem;
        font-weight: 600;
        color: #fcfcfc;
        margin-bottom: 0.1rem;
      }
      .des {
        // width: 4.17rem;
        font-size: 0.095rem;
        color: #cccccc;
        margin-bottom: 0.63rem;
        line-height: 0.13rem;
      }
      .btn_video {
        width: 1.36rem;
        height: 0.32rem;
        background: rgba(162, 162, 162, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        cursor: pointer;
        color: rgba(252, 252, 252, 0.8);
        font-size: 0.1rem;
        margin: 0 auto;
        &:hover {
          opacity: 0.7;
        }
        img {
          margin-left: 0.06rem;
          width: 0.14rem;
        }
      }
    }
  }

  .scan_wrap {
    position: relative;
    .scan_content {
      position: absolute;
      top: 0.32rem;
      left: 50%;
      transform: translateX(-50%);
      .title {
        font-size: 0.19rem;
        font-weight: 600;
        color: #fcfcfc;
        margin-bottom: 0.1rem;
      }
      .des {
        // width: 4.17rem;
        font-size: 0.095rem;
        color: #cccccc;
        margin-bottom: 0.63rem;
        line-height: 0.13rem;
      }
    }
  }

  .d3_wrap {
    position: relative;
    .d3_content {
      position: absolute;
      top: 1.54rem;
      left: 50%;
      transform: translateX(-50%);
      .title {
        font-size: 0.19rem;
        font-weight: 600;
        color: #fcfcfc;
        margin-bottom: 0.1rem;
      }
      .des {
        // width: 4.17rem;
        font-size: 0.095rem;
        color: #cccccc;
        margin-bottom: 0.29rem;
        line-height: 0.13rem;
      }
      .line {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin: 0 0.19rem;
        }
      }
    }
  }

  .fun_wrap {
    background: #fafafa;
    padding: 0.32rem 0;
    text-align: center;

    .title {
      font-size: 0.19rem;
      font-weight: 600;
      color: #333333;
      margin-bottom: 0.1rem;
    }
    .des {
      // width: 4.17rem;
      font-size: 0.095rem;
      color: #808080;
      line-height: 0.13rem;
      margin: 0 auto 0.29rem;
    }
    .fun_content {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      .left_menu {
        .item {
          width: 1.17rem;
          height: 0.42rem;
          background: #f5f5f5;
          border-radius: 0rem 0rem 0rem 0rem;
          border-bottom: 0.01rem solid #e8e8e8;
          line-height: 0.42rem;
          font-size: 0.11rem;
          color: #808080;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
          &:last-child {
            border: none;
          }
          &.active {
            color: #ffffff;
            background: linear-gradient(180deg, #5cd4ff 0%, #0ebfff 100%);
          }
        }
      }
      .right_content {
        width: 6.75rem;

        height: 2.92rem;
        background: #ffffff;
        background-size: cover;
        .swiper-container {
          width: 100%;
          height: 100%;
          .swiper-slide {
            video,
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .auto_wrap {
    padding: 0.32rem 0;
    .title {
      font-size: 0.19rem;
      font-weight: 600;
      color: #333333;
      margin-bottom: 0.1rem;
    }
    .des {
      // width: 4.17rem;
      font-size: 0.095rem;
      color: #808080;
      line-height: 0.13rem;
      margin: 0 auto 0.29rem;
    }
    .line {
      display: flex;
      justify-content: center;
      .item {
        margin: 0 0.32rem;
        img {
          width: 1.07rem;
          height: 2.29rem;
        }
        p {
          margin-top: 0.1rem;
          font-size: 19px;
          color: rgba(102, 102, 102, 0.9);
          line-height: 0.16rem;
        }
      }
    }
  }

  .num_wrap {
    padding: 0.32rem 0 ;
    background: #fafafa;
    .title {
      font-size: 0.19rem;
      font-weight: 600;
      color: #333333;
      margin-bottom: 0.1rem;
    }
    .des {
      // width: 4.17rem;
      font-size: 0.095rem;
      color: #808080;
      line-height: 0.13rem;
      margin: 0 auto 0.29rem;
    }
    .line {
      display: flex;
      justify-content: center;
      .item {
        margin: 0 0.07rem;
        position: relative;
        img {
          width: 2.55rem;
          height: 3.07rem;
        }
        p {
          margin-top: 0.1rem;
          color: #ffffff;
          font-size: 19px;
          line-height: 0.16rem;
          position: absolute;
          bottom: 60px;
          left: 30px;
          &:last-child {
            font-size: 12px;
            position: absolute;
            bottom: 30px;
            left: 30px;
            color: #cccccc;
          }
        }
      }
    }
  }

  .outside_wrap {
    padding: 0.32rem 0;
    background: url(../assets/outside_bg.png);
    background-size: cover;
    .title {
      font-size: 0.19rem;
      font-weight: 600;
      color: #333333;
      margin-bottom: 0.1rem;
    }
    .des {
      // width: 3.17rem;
      font-size: 0.095rem;
      color: #808080;
      line-height: 0.13rem;
      margin: 0 auto 0.29rem;
    }

    .line {
      display: flex;
      justify-content: center;
      .item {
        margin: 0 0.07rem;
        position: relative;
        img {
          width: 2.55rem;
          height: 2.45rem;
        }
        p {
          margin-top: 0.1rem;
          color: #ffffff;
          font-size: 19px;
          line-height: 0.16rem;
          position: absolute;
          bottom: 25px;
          left: 30px;
        }
      }
    }
  }

  
}

.model_wrap {
  z-index: 9999;
    width: 5rem;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, -50%);
    video {
      width: 100%;
    }
  }
@media screen and (max-width: 900px) {
  .model_wrap {
    z-index: 9999;
    width: 10rem;
    position: fixed;
    top: 50%;
    z-index: 1000;
    left: 50%;
    transform: translate(-50%, -50%);
    video {
      width: 100%;
    }
  }
}


</style>
